// Note: Currently can't export/import types, see https://github.com/vuejs/core/issues/4294#issuecomment-896082769

// export interface FormFieldProps {
//     label?: string,
//     modelValue?: string,
//     placeholder?: string,
//     autofocus?: boolean,
//     required?: boolean,
//
//     labelClass?: any,
//     inputClass?: any,
// }

export const defaults = {
    modelValue: '',
    placeholder: '',
    autofocus: false,

    labelClass: '',
    inputClass: '',
}