<script setup lang="ts">
const {labelClass} = useFormItem()

interface Props {
  text?: string,
  required?: boolean,
}

const props = withDefaults(defineProps<Props>(), {})
</script>

<template>
  <span :class="labelClass" v-if="text">
    <span v-html="text" /><template v-if="required">&nbsp;<span class="material-icons-outlined text-button-mini text-domain" :title="$t('_global.mandatory')">trip_origin</span></template>
  </span>
</template>