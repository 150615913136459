import type {Ref} from 'vue'

export default function formFieldExpose(input: Ref, props: any) {
    return {
        select: () => {
            input.value?.select()
        },
        getValue: () => {
            return props.modelValue
        },
        isRequired: () => {
            return props.required
        },
    }
}