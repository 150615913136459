export const useFormItem = () => {

  const labelClass = ref('block font-bold text-button-small mb-2')
  const inputClass = ref('block mb-1 w-full border-bento rounded-bento text-black px-3 py-2 border-black outline-none focus-visible:border-domain transition-colors')

  return {
    labelClass,
    inputClass,
  }

}
