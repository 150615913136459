<script setup lang="ts">
import {defaults} from "~/types/FormFieldProps"
import formFieldExpose from "~/components/atoms/form/form-field-expose"

interface Props {
  // This is common with other fields
  name?: string,
  label?: string,
  modelValue?: string,
  placeholder?: string,
  autofocus?: boolean,
  required?: boolean,
  error?: string|null,

  readonly?: boolean,
  wrapperClass?: any,
  labelClass?: any,
  inputClass?: any,

  // This is specific
  type?: string,
  maxLength?: number|null,
  autocapitalize?: null|string,
  autocomplete?: null|string,
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  ...defaults,
})

const {inputClass: baseInputClass} = useFormItem()

const input = ref<HTMLInputElement | null>(null)

defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

defineExpose(formFieldExpose(input, props))
</script>

<template>
  <label class="block" :class="[wrapperClass]">
    <AtomsFormLabel :text="label" :required="required" :class="[labelClass]" />
    <input :name="name"
           :class="[baseInputClass, inputClass, error ? '!border-red' : '']"
           ref="input"
           :type="type"
           :autofocus="autofocus"
           :autocapitalize="autocapitalize"
           :autocomplete="autocomplete"
           :required="required"
           :readonly="readonly"
           :placeholder="placeholder"
           :maxlength="maxLength"
           :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
    <AtomsFormFieldError :text="error" />
    <slot name="after-input"></slot>
  </label>
</template>